import { render, staticRenderFns } from "./QWeeklyOnboardingReportDataTable.vue?vue&type=template&id=19c876eb&scoped=true&"
import script from "./QWeeklyOnboardingReportDataTable.vue?vue&type=script&lang=js&"
export * from "./QWeeklyOnboardingReportDataTable.vue?vue&type=script&lang=js&"
import style0 from "./QWeeklyOnboardingReportDataTable.vue?vue&type=style&index=0&id=19c876eb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19c876eb",
  null
  
)

export default component.exports