<template>
    <div>
        <v-card>
            <v-container fluid class="ma-0 pa-0 q_leads_1" id="newagents_datatable">
                <v-row>
                    <v-col cols="3" sm="8" class="mb-0 pb-0">
                        <p class="q-display-3 pl-7 pb-0 pt-0 mt-0 mb-0 white--text">{{ title }}
                            <v-btn dark icon @click="refreshData">
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                        </p>
                        <p class="pt-0 mt-0 pl-5">
                            <!--
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filters
                            </v-btn>
                            //-->
                            <v-btn text small color="q_leads_4" @click="exportResults">
                                <v-icon small left>fas fa-file-export</v-icon> Export Results
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col cols="9" sm="4" class="pr-7 mb-0">
                        <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <!--</v-card-title>-->
            <v-row class="d-flex justify-start" v-if="toggleFilters">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
            </v-row>
            <v-data-table v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="ID" class="elevation-0" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading">
                <template v-slot:item.AgentEmail="{ item }">
                    <a :href="'mailto:' + item.AgentEmail" class="nowrap">{{ truncateEmail(item.AgentEmail) }}</a>
                </template>
                <template v-slot:item.AgentID="{ item }">
                    <span class="nowrap">
                        <v-btn color="q_new_b_1 white--text  body-2" :to="{ name: 'AgentDetail', params: { agentcode: item.AgentCode} }">View</v-btn>
                    </span>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QDataTableMixin from "../QDataTableMixin"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
const { Parser } = require('json2csv');

export default {
    name: "QWeeklyOnboardingReportDataTable",
    mixins: [QDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            //use this to "preset" a filter
            filters: {},
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load. 
            the_data: [],
            data_loading: false,
            total_num: 0,
            options: {
                'sortBy': ['ContractStartDt'],
                'sortDesc': [true]
            },
            entity: 'newagents'
        }
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            return this.the_data;
        },
        filter_keys: function() {
            return ['UserName'];
        },
        total_items: function() {
            return this.total_num;
        },
        available_filters: function() {
            return [];
        },
        the_headers: function() {
            return [{
                    text: 'Name',
                    value: 'AgentName',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                },
                {
                    text: 'Upline',
                    value: 'UplineAgentName',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'NPN',
                    value: 'NPN',
                    sortable: true,
                    filterable: true,
                    align: 'left',
                },
                {
                    text: 'Email',
                    value: 'AgentEmail',
                    sortable: true,
                    filterable: true,
                    align: 'left',
                },
                {
                    text: 'Contract Date',
                    value: 'ContractStartDt',
                    sortable: true,
                    filterable: true
                },
                {
                    text: '',
                    value: 'AgentID',
                    sortable: true,
                    filterable: true
                }
            ]
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            this.data_loading = true
            QuilityAPI.getWeeklyOnboardingReport(this.filters, { ...opt }).then(function(json) {
                g.data_loading = false
                g.$set(g, 'the_data', json.data);
                g.total_num = json.data.length
                g.$emit('loaded', {
                    total_items: json.data.length,
                    startDate: json.meta.startDate,
                    endDate: json.meta.endDate
                })
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
            });
            this.firstPull = false;
        }, 200),
        exportResults: function() {
            var fields = ['AgentName', 'AgentCode', 'NPN', 'AgentEmail', 'ContractStartDt', 'UplineAgentName', ];
            var opts = { fields };
            try {
                var parser = new Parser(opts);
                var csv = parser.parse(this.all_data);
                var fileURL = window.URL.createObjectURL(new Blob([csv]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                var fileName = this.slugify("Weekly Onboarding Report");
                fileLink.setAttribute('download', fileName + ".csv");
                document.body.appendChild(fileLink);
                fileLink.click();
            } catch (err) {
                console.error(err);
            }
        },
        truncateEmail: function(email) {
            let threshold = 30

            if (email.length <= threshold) {
                return email
            }

            // subtract 3 from threshold to account for the ellipsis
            return `${email.slice(0,(threshold - 3))}...`
        }
    },
    watch: {

    },
    components: {}
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

</style>
